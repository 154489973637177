#main {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.main-grid {
  margin-top: 40px;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 40px 1fr 30px;
  grid-template-areas:
  'logo'
  'content'
  'credits';
  max-width: 95%;
}

.main-text {
  color: white;
  padding: 12px;
  font-size: 30px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 3px;
}

.app-name {
  grid-area: logo;
  justify-self: flex-start;
  padding-left: 10px;
  align-items: center;
}

.credits {
  grid-area: credits;
  display: flex;
  justify-self: flex-end;
  align-items: center;
  color:  rgba(255,255,255,0.4);
  transition: color 0.2s;
}

.credits a {
  color:  rgba(255,255,255,0.4);
  cursor: pointer;
  transition: color 0.2s;
  text-decoration: none;
  padding-right: 10px;
}

.credits:hover, .credits a:hover {
  color: var(--color-text-light);
}

.background {
  z-index: -10;
  position: fixed;
  top: 0;
  left: 0;
}

.border{
  border: 3px solid white;
  width: 300px;
  height: 40px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  color:white;
  padding: 8px;
  filter: contrast(0.6);
}

.highlighted{
  border: 3px solid white;
  width: 300px;
  height: 40px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  color:white;
  padding: 8px;
  filter: drop-shadow(2px 4px 6px black);
}

.highlighted-text {
  font-size: 48px;
  color: white;
  display: flex;
  justify-content: center;
  padding-bottom: 12px;
  text-transform: uppercase;
  font-family: "Aladin", system-ui;
  font-weight: 400;
  font-style: normal;
}

.highlighted-text-sub {
  font-size: 24px;
  color: white;
  display: flex;
  justify-content: center;
  padding-bottom: 12px;
}

.background .img-background {
  position: fixed;
  height: 100%;
  width: 100%;
}

.content {
  grid-area: content;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-template-areas:
  'principal secondary';

  grid-gap: 30px;

  background-color: var(--content-bg);
  backdrop-filter: blur(30px);
  border-radius: 12px;
  padding: 30px;
  width: 100%;

}

.prayer-times-container{
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 20px;
}

div.principal {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  grid-area: principal;
}

.principal .get-location {
  background-color: var(--search-button-bg);
  padding: 3px 15px;
  justify-self: flex-start !important;
  border-radius: 4px;
  cursor: pointer;
  
  transition: background-color 0.2s;
}

.principal .get-location span{
  text-decoration: none;
  color: var(--color-text);
  font-weight: 600;
  font-size: 16px;
}

.principal .get-location:hover {
  background-color: var(--search-button-bg-hover);

}

div.principal .header {
  position: relative;
}

div.principal .header, div.principal .header form {
  display: flex;
  width: 95%;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
}

div.principal .header form {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas:
  'input'
  'extras';
}

div.principal .header form .input-wrapper {
  grid-area: input;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

div.principal .header form .extras {
  grid-area: extras;
  display: flex;
  justify-content: center;
  align-items: center
}

div.principal .header form .snackbar {
  color:red;
  font-weight: 600;
  font-size: 16px;
  background-color:  rgba(255, 255, 255, 0.582);
  border-radius: 4px;
  padding: 3px 10px;
  margin-left: 5px;;
}

div.principal .header .cityInput {
  width: 90%;
  height: 40px;
  border: 2px solid #FFFFFF;
  border-radius: 12px;
  background: none;
  padding: 10px 15px;
  outline: none;
  color: #FFFFFF;
  font-size: 16px;
}

.cityInput::placeholder {
  color: #FFFFFF;
}

div.principal .header .searchButton {
  color: #FFFFFF;
  background-color: var(--search-button-bg);
  height: 42px;
  width: 42px;
  border: none;
  border-radius: 12px;
  font-size: 22px;
  margin-left: 12px;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  outline: none;

  transition:  background-color 0.2s;
}

div.principal .header .searchButton:hover {
  background-color: var(--search-button-bg-hover);
}


div.principal .header .search-results {
  position: absolute;
  left: -2px;
  top: 50px;
  width: 85%;
  padding: 5px;
  justify-content: center;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  z-index: 10;

  visibility: hidden;
  transition: visibility 0.1s;

}

.cityInput:focus + div.search-results {
  visibility: visible;
}

div.principal .header .search-results .result-item {
  width: 90%;
  padding: 10px;
  font-size: 10px;
  background-color:  rgba(255, 255, 255, 0.6);
  transition: background-color 0.2s;
  cursor: pointer;
  justify-content: flex-start;
  display: flex;
  align-items: center;
}

div.principal .header .search-results .result-item:hover {
  background-color:  rgba(255, 255, 255, 0.9);
}
.result-item:first-child {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}
.result-item:last-child {
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  border-bottom: none;
}
div.principal .header .search-results .result-item .result-flag {
  max-width: 30px;
  border-radius: 4px;
  margin-right: 8px;
}
div.principal .header .search-results .result-item .result-city {
  font-size: 14px;
}

div.principal .result {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid var(--color-text-light);
  padding: 20px 0px;
}

div.principal .result img {
  height: 60px;
}

div.principal .result h1 {
  position: relative;
  font-size: 80px;
  color: #FFFFFF;
}

div.principal .result h1 span {
  font-size: 20px;
  margin-left: 10px;
}

div.principal .result .description {
  font-size: 20px;
  line-height: 40px;
  color: var(--color-text-light);
}

div.principal .result .local {
  font-size: 22px;
  line-height: 40px;
  color: #FFFFFF;
  display: flex;
  align-items: center;
}

div.principal .result .local img {
  max-height: 14px;
}

div.principal .other-results {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

div.principal .other-results .other {
  font-size: 18px;
  text-align: center;
  color: var(--color-text-light);
  padding: 18px;
}

div.principal .other-results .other span {
  font-size: 26px;
  line-height: 40px;
}

div.secondary {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 80px 1fr;
  grid-template-areas:
  'header'
  'figure'
  'link'
  ;
  width: 100%;
}

div.secondary .secondary-results {
  grid-area: header;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

div.secondary .secondary-results .other-secondary-results {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  text-align: left;
  color: var(--color-text);
  line-height: 25px;
  padding: 18px;
}

.other-secondary-results p {
  margin-left: 10px;
}

.other-secondary-results .icon-secondary-results {
  font-size: 50px
}

.other-secondary-results .humidity{
  font-size: 35px;
  color: aquamarine;
}

.secondary .landing-figure {
  display: flex;
  align-items: center;
  justify-content: center;
  grid-area: figure;
  padding: 30px 20px;
}

.secondary .landing-figure > img {
  max-width: 70%;
}

.secondary .go-maps {
  grid-area: link;
  justify-self: center;
  cursor: pointer;
}

.secondary .go-maps a {
  text-decoration: none;
  color: var(--color-text);
  font-weight: 700;
  padding: 10px 15px;
  border-radius: 12px;
  border: 2px solid var(--color-text);
  background-color: var(--color-content-bg);
  display: flex;
  align-items: center;

  transition: all 0.2s;
}

.secondary .go-maps:hover a {
  color: var(--color-content-bg);
  background-color: var(--color-text)
}

@media (max-width: 1250px) {
  .main-grid {
    margin-top: 10px;
    grid-template-rows: 1fr;
    grid-template-areas: 'content';
  }

  .app-name {
    display: none;
  }

  .credits {
    display: none;
  }

  div.principal .result {
    padding: 16px 0px;
  }

  div.principal .get-location, div.principal .header form .snackbar {
    padding: 1px 10px
  }

  div.principal .header form .snackbar {
    font-size: 14px;
  }

  div.principal .get-location span {
    font-size: 14px;
  }

  div.principal .result img {
    height: 50px;
  }
  
  div.principal .result h1 {
    font-size: 60px;
  }
  
  div.principal .result h1 span {
    font-size: 16px;
    margin-left: 10px;
  }
  
  div.principal .result .description {
    font-size: 16px;
    line-height: 40px;
    color: var(--color-text-light);
  }
  
  div.principal .result .local {
    font-size: 16px;
    line-height: 40px;
    color: #FFFFFF;
  }

  div.principal .other-results .other {
    font-size: 14px;
  }
  
  div.principal .other-results .other span {
    font-size: 16px;
  }
  
  div.secondary .secondary-results .other-secondary-results {
    flex-direction: column;
    font-size: 12px;
    text-align: center;
  }

  div.secondary .secondary-results .other-secondary-results p {
    margin: 0;
  }

  div.secondary .secondary-results .icon-secondary-results {
    height: 30px;
    margin-bottom: 5px;
    align-items: center;
    display: flex;
  }

  div.secondary .go-maps a {
    font-size: 14px;
    padding: 7px 10px;
  }
}

@media (max-width: 980px) {
  #main {
    align-items: flex-start;
  }
  
  .background .img-background {
    position: fixed;
    height: 180vh;
    width: auto;
  }

  .main-grid {
    margin-top: 40px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 70px 1fr 70px;
    grid-template-areas:
    'logo'
    'content'
    'credits';
    max-width: 95%;
  }

  .content {
    grid-area: content;
    display: grid;
    margin-bottom: 50px;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
    'principal'
    'secondary';
    margin-bottom: 0;
    padding: 30px 15px;
  }
  
  .app-name, .credits {
    display: flex;
    justify-self: center;
    align-self: center;

    justify-content: center;
    align-items: center;
  }

  .credits a, .app-name{
    padding: 0;
  }

  div.principal {
    justify-content: space-evenly;
  }
  div.principal .result {
    padding: 25px 0px;
  }

  div.principal .get-location, div.principal .header form .snackbar {
    padding: 3px 10px
  }

  div.principal .header form .snackbar {
    font-size: 14px;
  }

  div.principal .get-location span {
    font-size: 14px;
  }

  div.principal .header .cityInput {
    font-size: 16px;
  }

  div.principal .result img {
    height: 70px;
  }
  
  div.principal .result h1 {
    font-size: 80px;
  }
  
  div.principal .result h1 span {
    font-size: 16px;
    margin-left: 10px;
  }

  div.principal .other-results {
    border-bottom: 1px solid var(--color-text-light);
  }
  
  div.principal .other-results .other span {
    font-size: 20px;
    line-height: 40px;
  }

  div.secondary .secondary-results {
    align-items: center;
  }

  div.secondary .secondary-results .icon-secondary-results {
    height: 30px;
    margin-bottom: 5px;
    align-items: center;
    display: flex;
  }

  div.secondary .secondary-results .other-secondary-results p{
    margin-left: 0;
  }

  .secondary .landing-figure > img {
    max-width:100%;
  }

  div.secondary .go-maps a {
    font-size: 16px;
    padding: 10px 12px;
  }
}