@import url('https://fonts.googleapis.com/css2?family=Playball&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Aladin&family=Playball&display=swap');

:root {
  --transparent: rgba(255,255,255,0);
  --color-text: #FFF;
  --color-text-light: rgba(255,255,255,0.7);
  --search-button-bg: #15C6BF;
  --search-button-bg-hover: #14afaa;
  --content-bg: rgba(170, 170, 170, 0.3);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
}

body, input {
  font-family: 'Nunito', sans-serif;
}